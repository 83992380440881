export function getYoutubeId(youtubeUrl) {
  if (!youtubeUrl) return null;
  // eslint-disable-next-line no-useless-escape
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|shorts\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = youtubeUrl.match(regExp);

  if (match && match[2].length === 11) {
    return match[2];
  } else {
    return null;
  }
}

export function getYoutubeEmbedUrl(youtubeUrl) {
  if (youtubeUrl) {
    let youtubeId = getYoutubeId(youtubeUrl);
    if (youtubeId !== null) {
      return `https://www.youtube.com/embed/${youtubeId}`;
    }
    return null;
  }
  return null;
}
