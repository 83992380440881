<template>
  <div class="container bg-white" id="support-leads">
    <div class="row">
      <div class="col-xs-18">
        <h3>{{ $t('tos.supportLeads.title') }}</h3>
        <ol>
          <li>
            <div v-html="$t('tos.supportLeads.line1')"></div>
            <br />
            <div v-html="$t('tos.supportLeads.line2')"></div>
          </li>
          <li>
            <div v-html="$t('tos.supportLeads.line3')"></div>
            <ol type="i">
              <li v-html="$t('tos.supportLeads.line4')"></li>
              <li v-html="$t('tos.supportLeads.line5')"></li>
            </ol>
          </li>
          <li>
            <div v-html="$t('tos.supportLeads.line6')"></div>
            <ol type="i">
              <li v-html="$t('tos.supportLeads.line7')"></li>
              <li v-html="$t('tos.supportLeads.line8')"></li>
              <li v-html="$t('tos.supportLeads.line9')"></li>
            </ol>
          </li>
          <li>
            <div v-html="$t('tos.supportLeads.line10')"></div>
            <ol type="i">
              <li v-html="$t('tos.supportLeads.line11')"></li>
              <li v-html="$t('tos.supportLeads.line12')"></li>
            </ol>
          </li>
          <li>
            <div v-html="$t('tos.supportLeads.line13')"></div>
            <ol type="i">
              <li v-html="$t('tos.supportLeads.line14')"></li>
              <li v-html="$t('tos.supportLeads.line15')"></li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'definisi',
};
</script>
