<template>
  <div v-if="isSecondary">
    <div id="contact-wa-btn" @click="handleContact" style="cursor: pointer">
      <div class="btn btn--whatsapp action--button">
        <img src="@/assets/img/icons/whatsapp-1.svg" alt="Whatsapp" class="mr-2" />
        <span>{{ $t('general.contactWa') }}</span>
      </div>
    </div>
    <div id="contact-me-btn" @click="handleEnquiry" style="cursor: pointer">
      <div class="btn btn-primary action--button">
        <img src="@/assets/img/icons/phone-2.svg" alt="Phone" class="mr-2" />
        <span>{{ $t('general.contactMe') }}</span>
      </div>
    </div>
  </div>
  <div v-else>
    <div id="contact-us-btn" @click="handleContact" style="cursor: pointer">
      <div class="btn btn--whatsapp action--button">
        <img src="@/assets/img/icons/whatsapp-1.svg" alt="Whatsapp" class="mr-2" />
        <span v-if="projectTypeId === 2">{{ $t('general.contactDeveloper') }}</span>
        <span v-else>{{ $t('general.contactUs') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'contact-button',
  props: [
    'type',
    'name',
    'agentUuid',
    'price',
    'listingUuid',
    'contactNumber',
    'ownerName',
    'contactNumbers',
    'projectTypeId',
    'projectUuid',
  ],
  mixins: [HelperMixin],
  data: () => ({
    windowReference: null,
  }),
  computed: {
    isSecondary() {
      const category = this.$route.params.category;
      const splitCategory = category ? category.split('-') : null;
      return (
        splitCategory &&
        (splitCategory[0] === 'properti' || splitCategory[splitCategory.length - 1] === 'secondary')
      );
    },
  },
  methods: {
    async handleEnquiry() {
      this.$modal.show('modal--contact-us', {
        listingUuid: this.listingUuid,
      });
    },
    handleContact() {
      if (this.contactNumbers && this.projectTypeId === 2) {
        this.$modal.show('modal--multiple-contact', {
          contacts: this.contactNumbers,
          agentUuid: this.agentUuid,
          listingUuid: this.listingUuid,
          projectUuid: this.projectUuid,
        });
      } else {
        try {
          const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
          if (isSafari) {
            this.windowReference = window.open();
          }
          this.handleContactAction(isSafari);
        } catch (e) {
          console.log('ERROR HANDLE CONTACT: ', e);
        }
      }
    },
    async handleContactAction(isSafari) {
      let url = '';
      const contactNumber = this.contactNumber
        ? this.contactNumber
        : '+' + this.$t('general.phone1');
      if (this.contactNumber == null) {
        // TIPE LISTING PRIMARY
        let textMessage = '';
        if (this.type === 'unitType') {
          textMessage +=
            'Halo tim BeliRumah! Saya ingin mendapat informasi lebih mengenai tipe unit ini ';
        } else if (this.type === 'unit') {
          textMessage +=
            'Halo tim BeliRumah! Saya ingin mendapat informasi lebih mengenai unit ' +
            this.name +
            ' ';
        } else {
          textMessage +=
            'Halo tim BeliRumah! Saya ingin mendapat informasi lebih mengenai proyek ini ';
        }

        const { data } = await this.$http.post('/api/lead/create', {
          agent_uuid: this.agentUuid,
          listing_uuid: this.listingUuid,
          project_uuid: this.projectUuid,
          source: 'website',
        });
        textMessage +=
          this.$store.state.global.baseUrl + this.$route.path + `?lead_uuid=${data.data.lead_uuid}`;
        url = `https://api.whatsapp.com/send?phone=${contactNumber}&text=${textMessage}`;
      } else {
        // TIPE LISTING SECONDARY

        // eslint-disable-next-line no-unused-vars
        const { data } = await this.$http.post('/api/lead/create', {
          agent_uuid: this.agentUuid,
          listing_uuid: this.listingUuid,
          project_uuid: this.projectUuid,
          source: 'website',
        });
        let textMessage = '';
        textMessage += `Halo ${this.ownerName}! Saya ingin mendapat informasi lebih mengenai properti ini `;
        textMessage += this.$store.state.global.baseUrl + this.$route.path;
        url = `https://api.whatsapp.com/send?phone=${contactNumber}&text=${textMessage}`;
      }

      if (isSafari) {
        if (this.windowReference) {
          this.windowReference.location = url;
        }
      } else {
        // Not working in safari (window.open in async function)
        window.open(url, '_blank');
      }
    },
  },
};
</script>
