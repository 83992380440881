<template>
  <div class="container bg-white" id="koin">
    <div class="row">
      <div class="col-xs-18">
        <h3>{{ $t('tos.koin.title') }}</h3>
        <ol>
          <li>
            <div v-html="$t('tos.koin.line1')"></div>
            <ol type="i">
              <li v-html="$t('tos.koin.line2')"></li>
              <li v-html="$t('tos.koin.line3')"></li>
              <li v-html="$t('tos.koin.line4')"></li>
            </ol>
          </li>
          <li>
            <div v-html="$t('tos.koin.line5')"></div>
            <ol type="i">
              <li v-html="$t('tos.koin.line6')"></li>
              <li v-html="$t('tos.koin.line7')"></li>
            </ol>
          </li>
          <li>
            <div v-html="$t('tos.koin.line8')"></div>
            <ol type="i">
              <li v-html="$t('tos.koin.line9')"></li>
              <li v-html="$t('tos.koin.line10')"></li>
            </ol>
          </li>
          <li>
            <div v-html="$t('tos.koin.line11')"></div>
            <ol type="i">
              <li v-html="$t('tos.koin.line12')"></li>
              <li v-html="$t('tos.koin.line13')"></li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'definisi',
};
</script>
