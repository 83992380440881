<template>
  <modal
    :name="modalName"
    adaptive
    :width="500"
    @before-open="beforeOpen"
    height="auto"
    class="modal--unit-detail"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper hide--in-desktop hide--in-tablet">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ title }}
      </div>
      <section class="modal--container">
        <div class="title--text hide--in-mobile">
          {{ title }}
        </div>
        <div class="wrapper--main" v-if="unit">
          <div class="row">
            <div class="col-6 left--col">{{ $t('general.unit') }}</div>
            <div class="col-6 right--col">
              {{ unitName }}
            </div>
          </div>
          <div class="row">
            <div class="col-6 left--col">{{ $t('general.price') }}</div>
            <div class="col-6 right--col">{{ unit.price_str }}</div>
          </div>
          <div class="row" v-show="unit.building_size">
            <div class="col-6 left--col">{{ $t('addProject.buildingSize') }}</div>
            <div class="col-6 right--col">{{ unit.building_size }} m2</div>
          </div>
          <div class="row" v-if="unit.land_size">
            <div class="col-6 left--col">{{ $t('addProject.landSize') }}</div>
            <div class="col-6 right--col">{{ unit.land_size }} m2</div>
          </div>
          <div class="row" v-if="unit.total_floor">
            <div class="col-6 left--col">{{ $t('general.totalFloor') }}</div>
            <div class="col-6 right--col">{{ unit.total_floor }}</div>
          </div>
          <div class="row" v-if="unit.floor">
            <div class="col-6 left--col">{{ $t('general.floor') }}</div>
            <div class="col-6 right--col">{{ unit.floor }}</div>
          </div>
          <div class="row" v-show="unit.bedroom">
            <div class="col-6 left--col">{{ $t('general.bedroom') }}</div>
            <div class="col-6 right--col">{{ unit.bedroom }}</div>
          </div>
          <div class="row" v-show="unit.bathroom">
            <div class="col-6 left--col">{{ $t('general.bathroom') }}</div>
            <div class="col-6 right--col">{{ unit.bathroom }}</div>
          </div>
          <div class="row" v-show="unit.electricity_capacity">
            <div class="col-6 left--col">{{ $t('general.electricity') }}</div>
            <div class="col-6 right--col">{{ unit.electricity_capacity }}</div>
          </div>
          <div class="row" v-show="unit.furnishing_status">
            <div class="col-6 left--col">{{ $t('general.furniture') }}</div>
            <div class="col-6 right--col">{{ unit.furnishing_status }}</div>
          </div>
          <div class="row" v-show="unit.building_direction">
            <div class="col-6 left--col">{{ $t('general.buildingDirection') }}</div>
            <div class="col-6 right--col">{{ unit.building_direction }}</div>
          </div>
          <div class="bottom--part">
            <div class="bottom--title">{{ $t('general.interested') }}</div>
            <contact-button
              type="unit"
              :name="unitName"
              :agentUuid="agentUuid"
              :listingUuid="unit.uuid"
              :price="unit.price_num"
              :project-type-id="project.project_type_id"
              :contactNumbers="project.project_type_id === 2 ? project.contacts : null"
            ></contact-button>
          </div>
        </div>
      </section>
      <button type="button" class="close hide--in-mobile" @click="close">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
const ContactButton = () => import('@/components/project-detail/partials/contact-button');

export default {
  components: {
    ContactButton,
  },
  data: () => ({
    modalName: 'modal-unit-detail',
    title: '',
    unit: null,
    project: null,
  }),
  computed: {
    ...mapState({
      agentUuid: (state) => state.v2.project.agentUuid,
      propertyTypeEnum: (state) => state.v2.masters.propertyTypeEnum,
    }),
    unitName() {
      let unitName = '';
      if (this.unit.unit_block) {
        unitName += this.$t('general.unitBlock') + ' ' + this.unit.unit_block + ' ';
      } else if (this.unit.unit_category) {
        unitName += this.unit.unit_category + ' ';
      }
      unitName += this.$t('general.unitNumber') + ' ' + this.unit.unit_number;
      return unitName;
    },
  },
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
    beforeOpen(event) {
      console.log('OPEN UNIT DETAIL: ', event.params.unit);
      this.unit = event.params.unit;
      this.title = this.unit.unit_category + ', ' + this.unit.unit_type_name;
      this.project = event.params.project;
    },
  },
};
</script>
