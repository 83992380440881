<template>
  <div>
    <v-slide-group show-arrows class="carousel--wrapper project--slick">
      <v-slide-item v-for="(content, index) in contents" :key="index" class="carousel--slide">
        <card-unit
          :key="content.uuid"
          :unit="content"
          :allowMultiImage="false"
          :project="project"
        />
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
const CardUnit = () => import('@/components/utils/card-unit');

export default {
  components: {
    CardUnit,
  },
  props: {
    contents: {
      default: [],
      require: true,
    },
    project: {
      default: null,
      require: true,
    },
  },
  data: () => ({}),
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/utils/variables';
.project--slick {
  height: 220px;

  @media #{$tablets} {
    height: 220px;
  }

  @media #{$phones} {
    height: 180px;
  }
}
</style>
